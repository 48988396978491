angular.module('org-admin')
  .component('hqDashboard', {
    templateUrl: '/static/org/hq-dashboard/hq-dashboard.html',
    controller: function(UserPreferences, PeopleAndOrgsUiService, currentOrg, currentUser) {
      const $ctrl = this

      UserPreferences.get().then((result) => {
        const preferences = result.hq_dashboard.value
        const params = new URLSearchParams(preferences)

        $ctrl.version = params.get('version') || '1'

        if ($ctrl.version > 1) loadNewDashboard(preferences)
      })

      async function loadNewDashboard(preferences) {
        const { firstname: first_name, lastname: last_name, id, } = currentUser
        PeopleAndOrgsUiService.loadScripts('se-hq-dashboard')
        $ctrl.dashboardConfig = JSON.stringify({
          organization: currentOrg,
          user: { id, first_name, last_name },
          preferences,
          policy: await getDashboardPolicy(),
        })
      }

      // TODO: make async policy check once endpoint exists
      function getDashboardPolicy() {
        return Promise.resolve({
          access: true,
          add_people: true,
          add_registration: true,
          add_season: true,
          view_financials: true,
          view_memberships: true,
          view_people: true,
          view_registrations: true,
          view_seasons: true,
        })
      }
    }
  })
