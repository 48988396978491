angular.module('org-admin')
  .component('affiliationsDetail', {
    templateUrl: '/static/org/affiliations/affiliations-detail-component.html',
    controllerAs: '$ctrl',
    controller: function(
      _,
      $q,
      $routeParams,
      currentUser,
      routeTitle,
      showError,
      Affiliation,
      Organization,
      pageViewHandler,
      currentOrg,
      PeopleAndOrgsUiService,
      Policy,
    ) {

      var $ctrl = this

      $ctrl.$onInit = load

      function load() {
        var requests = {
          affiliations: Affiliation.findAll(affiliationParams()),
          org: Organization.findPublic($routeParams.childOrgId),
          topDownOrgAccess: Policy.topDownOrgAccess($routeParams.childOrgId),
        }
        $q.all(requests).then(loadSuccess, showError)
      }

      function loadSuccess(data) {
        var affiliation = data.affiliations[0]
        var org = data.org
        var topDownOrgAccess = data.topDownOrgAccess?.actions?.top_down_org_access

        $ctrl.affiliation = affiliation
        $ctrl.childOrg = org
        $ctrl.parentOrg = affiliation.parent_organization
        $ctrl.classification = _.capitalize($routeParams.classification)
        $ctrl.adminName = _.compact([org.admin_first_name, org.admin_last_name]).join(' ')
        $ctrl.showTopDownOrgAccess = topDownOrgAccess || currentUser.superRole

        setTimeout(initAffiliationAdminsElement)
        setTimeout(initAffiliationContactInfoElement)

        $ctrl.loaded = true
        pageViewHandler.addPageDepths({ depth4: $ctrl.classification + 's' })

        routeTitle.setParams({ child_org_name: org.name })
      }

      function affiliationParams() {
        return {
          per_page: 1,
          classification_name: $routeParams.classification,
          ancestor_organization_id: currentOrg.id,
          child_organization_id: [parseInt($routeParams.childOrgId, 10)]
        }
      }

      function initAffiliationAdminsElement() {
        var CONTAINER_ID = `affiliation-admins${$ctrl.childOrg.id}`
        PeopleAndOrgsUiService.loadScripts('se-affiliation-admins', function() {
          PeopleAndOrgsUiService.createElement(
            'se-po-affiliation-admins',
            CONTAINER_ID,
            {
              options: JSON.stringify({
                orgId: currentOrg.id,
                childOrgId: $ctrl.childOrg.id,
                credId: $ctrl.affiliation.credentials[0].id
              })
            }
          ),
          true
        })
      }

      function initAffiliationContactInfoElement() {
        var CONTAINER_ID = `affiliation-contact-info${$ctrl.childOrg.id}`
        PeopleAndOrgsUiService.loadScripts('se-affiliation-contact-card', function() {
          PeopleAndOrgsUiService.createElement(
            'se-affiliation-contact-card',
            CONTAINER_ID,
            {
              options: JSON.stringify({
                organization: $ctrl.affiliation.child_organization,
                affiliationType: $ctrl.classification
              })
            },
            true
          )
        })
      }
    }
  })
