angular.module('org-admin')
  .directive('invoiceApplyCredit', function() {
    return {
      scope: {},
      bindToController: {
        invoice: '<',
        cancel: '=',
        confirm: '=',
        dialogOptions: '='
      },
      controllerAs: 'ctrl',
      templateUrl: '/static/org/invoices/invoice-apply-credit.html',
      controller: function(setAs, Invoice, currentOrg, launchDarklyFlags, Payment) {
        var ctrl = this

        ctrl.currentOrg = currentOrg
        ctrl.isRequired = true
        ctrl.showStaticOptions = false
        ctrl.staticOptionsMessage = ''
        ctrl.hasErrors = false
        ctrl.selectedOption = null
        ctrl.sale_adjustments = []
        ctrl.reasons = []
        ctrl.usableCreditReason
        ctrl.loaded = false

        ctrl.$onInit = async function() {
          ctrl.usableCreditReason = launchDarklyFlags.creditReasons
          ctrl.originalPayments = angular.copy(await ctrl.loadPayments())
          ctrl.payments = angular.copy(ctrl.originalPayments)
          ctrl.installments = {}
          ctrl.totalCredit = 0
          ctrl.selectedOption = ctrl.selectedOption || null
          ctrl.totalOutstanding = getTotalOutstanding()
          ctrl.editableInstallments = _.sortBy(getEditableInstallments(), 'due_date')
          ctrl.notEditableInstallments = _.sortBy(getNotEditableInstallments(), 'due_date')
          ctrl.noUpfrontPaymentOffset = getUpfrontPaymentOffset()
          if (ctrl.payments.length) {
            ctrl.loaded = true
          }
          if (ctrl.usableCreditReason) {
            ctrl.reasons = await getCreditReasons()
          }
          if (ctrl.editableInstallments.length === 1) {
            ctrl.updatePaymentAmount(ctrl.editableInstallments[0])
          }
        }

        ctrl.validTotalOutstanding = function() {
          return checkValidityOfTotal(ctrl.totalOutstanding)
        }

        ctrl.validTotalCredit = function() {
          return checkValidityOfTotal(ctrl.totalCredit)
        }

        ctrl.paymentAmountZero = function(payment) {
          return ctrl.getOriginalAmount(payment) === '0.00'
        }

        ctrl.updatePaymentAmount = function(payment) {
          var originalAmount = ctrl.getOriginalAmount(payment)
          payment.creditAmount = parseFloat(payment.creditAmount) || 0
          if (ctrl.installments[payment.id] || ctrl.editableInstallments.length === 1) {
            payment.amount = originalAmount - payment.creditAmount
          }
          else {
            payment.amount = originalAmount
          }
          ctrl.totalCredit = getTotalCredit()
          ctrl.totalOutstanding = getTotalOutstanding()
        }

        ctrl.loadPayments = async function() {
          return await Payment.adjustmentCountsByDueDate(ctrl.invoice.id)
        }

        ctrl.getOriginalAmount = function(paymentAtIndex) {
          var originalPayment = _.find(ctrl.originalPayments, function(payment) {
            return payment.id === paymentAtIndex.id
          })
          return originalPayment.amount
        }

        ctrl.submit = function() {
          var payload = ctrl.setPayload()

          return ctrl.invoice.credit(payload)
            .then(ctrl.confirm)
            .catch(setAs(ctrl, 'failure'))
        }

        // Private functions
        function getUpfrontPaymentOffset() {
          return ctrl.invoice.hasUpfrontPayment() ? 0 : 1
        }

        function checkValidityOfTotal(sum) {
          return sum >= 0 && sum <= ctrl.invoice.amount_outstanding
        }

        function isLockedPaymentStatus(payment) {
          return payment.status === 'chargeback' || payment.status === 'disputed' || payment.status === 'canceled'
        }

        function getNotEditableInstallments() {
          return _.filter(ctrl.payments, function(payment) {
            if (isLockedPaymentStatus(payment)) return true
            return payment.is_paid
          })
        }

        function getEditableInstallments() {
          return _.filter(ctrl.payments, function(payment) {
            if (isLockedPaymentStatus(payment)) return false
            return !payment.is_paid
          })
        }

        function getOutstandingUnpaid() {
          return ctrl.invoice.amount_outstanding
        }

        function getTotalCredit() {
          var paymentAmounts = _.pluck(ctrl.editableInstallments, 'amount')
          var totalPaymentAmount = getSum(paymentAmounts)
          return _.round(getOutstandingUnpaid() - totalPaymentAmount, 2)
        }

        function getSum(amounts) {
          return _.reduce(amounts, function(total, amount) {
            total = parseFloat(total) || 0
            amount = parseFloat(amount) || 0
            return total + amount
          })
        }

        function getTotalOutstanding() {
          return _.round(getOutstandingUnpaid() - ctrl.totalCredit, 2)
        }

        async function getCreditReasons() {
          return await Invoice.getCreditReasons(ctrl.currentOrg.id)
        }

        ctrl.setPayload = function() {
          if (ctrl.usableCreditReason) {
            var saleAdjustments = []
            angular.forEach(ctrl.payments, function(payment) {
              var fieldName = 'credit_reason_' + payment.id
              var formField = ctrl.applyCreditForm[fieldName]

              if (formField && Object.prototype.hasOwnProperty.call(formField, '$modelValue')) {
                var selectedOption = formField.$modelValue
                payment.selectedOption = selectedOption

                if (selectedOption === null || selectedOption === undefined) {
                  formField.$setValidity('required', false)
                }
                else {
                  formField.$setValidity('required', true)
                }
                var saleAdjustment = {
                  amount: payment.creditAmount,
                  debit_credit: 'credit',
                  credit_reason: Number(selectedOption.id) ?
                    { id: selectedOption.id } :
                    { title: selectedOption.title || selectedOption, tenant_id: ctrl.currentOrg.id },
                  notes: ctrl.notes
                }
                saleAdjustments.push(saleAdjustment)
              }
            })
            var payload = {
              sale_adjustment: saleAdjustments,
              sale_id: ctrl.invoice.id,
              payments: ctrl.payments
            }
            return payload
          }
          //Creates the payload when LD disables the use of credit reasons
          else {
            var legacyPayload = {
              sale_adjustment: {
                amount: ctrl.totalCredit,
                debit_credit: 'credit',
                notes: ctrl.notes
              },
              sale_id: ctrl.invoice.id,
              payments: ctrl.payments
            }
            return legacyPayload
          }
        }
      }
    }
  })
