(function() {

  var $ = angular.element

  angular.module('pl-shared')


  // EXAMPLE USE:
  // <svg-sprite src="'/images/icon-sprite.svg'"></svg-sprite>

    .directive('svgSprite', function($http, $log) {
      return {
        restrict: 'E',
        scope: {
          src: '@'
        },
        link: function($scope, $element) {
          if (!$scope.src) return $log.error('svg-sprite `src` attribute cannot be blank')

          $http
            .get($scope.src)
            .success(function(src) { $element.replaceWith(src) })
            .error($log.error)
        }
      }
    })

  // EXAMPLE USE:
  // <svg-img class="pl-icon pl-icon--player" use="avatar"></svg-img>
  // <svg class="pl-icon pl-icon--player"><use xlink:href="#svg-avatar"></use></svg>

    .directive('svgImg', function($log) {
      return {
        restrict: 'E',
        scope: {
          class: '=',
          style: '=',
          use: '='
        },
        link: function($scope, $element) {
          var lastElement = $element
          $scope.$watchGroup(['class', 'use'], function() {
            if (!$scope.use) return $log.error('svg-img `use` attribute cannot be blank')
            var classes = ['svg-' + $scope.use, $scope.class || '']
            var classAttr = ' class="' + classes.join(' ') + '"'
            var styleAttr = $scope.style ? ' style="' + $scope.style + '"' : ''
            var $svg = $('<svg' + classAttr + styleAttr + '><use xlink:href="#svg-' + $scope.use + '"></use></svg>')
            lastElement.replaceWith($svg)
            lastElement = $svg
          })
          $scope.$on('$destroy', function() {
            lastElement.remove()
          })
        }
      }
    })

})()
