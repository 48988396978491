angular.module('pl-shared')

/*

    REGEX EXAMPLES:

    1. Match a single query param value...
       –––––––––––––––––––––––([^&]+)––
       /^search\/path?.*param=([^&]+)/i

    2. Match part of a path, stripping prefixes, suffixes, and query params...
       ––––––––––––––––––––––––––––(.+?)––––––––––––––––––––––––––––––––––––––––––––––––
       /(?:^(?:pre\/fix|prefix)\/)*(.+?)(?=\/(?:$|\?)|\?|$|\/(?=suffix|other\/suffix))/i

  */

  // Define domain matchers and path parsers for all know social providers. The first match will be returned.
  // It's a good idea to put the search queries first, since search paths might also match other parsers.
  .factory('socialLinkProviders', function() {
    return {
      facebook: {
        matcher: /facebook/i,
        parsers: [
          /^search\/top?.*q=([^&]+)/i,
          /(?:^(?:#!|home\.php#!|pg|pages|groups|people)\/)*(.+?)(?=\/(?:$|\?)|\?|$|\/(?=timeline|about|messages|notifications|admin_activities|posts))/i,
        ]
      },
      twitter: {
        matcher: /twitter/i,
        parsers: [
          /(?:^(?:#!)\/)*(.+?)(?=\/(?:$|\?)|\?|$)/i
        ]
      },
      instagram: {
        matcher: /instagram/i,
        parsers: [
          /(?:^(?:explore\/locations|explore\/tabs)\/)*(.+?)(?=\/(?:$|\?)|\?|$)/i
        ]
      },
      youtube: {
        matcher: /youtube/i,
        parsers: [
          /^watch?.*v=([^&]+)/i,
          /^results?.*search_query=([^&]+)/i,
          /^playlist?.*list=([^&]+)/i,
          /(?:^(?:user|channel|c)\/)*(.+?)(?=\/(?:$|\?)|\?|$|\/(?=featured|videos))/i,
        ]
      }
    }
  })

  // Parses a social media url down to a smaller display format (username, if possible)
  // Example: 'https://www.facebook.com/OntarioIsSoccer/' | socialLink  =>  OntarioIsSoccer
  .filter('socialLink', function(socialLinkProviders) {
    return function(href) {
      if (typeof href !== 'string') return ''

      var url, path, originalhref = href

      if (!/:\/\//.test(href)) href = 'http://' + href // make sure we have a protocol

      // Bad hrefs cannot be parsed correctly, so we fall back to string logic
      try {
        url = new window.URL(href)
        if (href.indexOf(url.origin) !== 0) { throw new Error() } // likely not a full url, i.e. @shomething/foo
        path = url.href.replace(url.origin, '').replace(/^\//, '')
      }
      catch (e) {
        url = path = originalhref // ¯\_(ツ)_/¯
      }

      // Named loop so we can break out from the nested loop
      matcher: for (var slp in socialLinkProviders) {
        var provider = socialLinkProviders[slp]
        if (!provider.matcher.test(href)) continue

        for (var i = 0; i < provider.parsers.length; i++) {
          var parser = provider.parsers[i]
          if (parser.test(path)) {
            var match = path.match(parser)
            if (match) path = match[1]
            break matcher
          }
        }
      }

      return decodeURIComponent(path)
    }
  })
