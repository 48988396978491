angular.module('pl-shared')
  .factory('CredentialDefinition', function(DS, paginate, _) {
    var _cache = {}
    var CredentialDefinition = DS.defineResource({
      name: 'credential_definitions',
      endpoint: '/credential_definitions',
      basePath: DS.adapters.se_api.defaults.basePath,
      defaultAdapter: 'se_api'
    })

    CredentialDefinition.forOrg = function(orgId) {
      var cacheKey = 'credentialDefinition:' + orgId
      return cache(cacheKey, function() {
        return CredentialDefinition.findAll({
          organization_id: orgId
        })
      })
    }

    CredentialDefinition.forOrgWithTag = function(orgId, tag) {
      var cacheKey = 'credentialDefinition:' + orgId + ':' + tag
      return cache(cacheKey, function() {
        return CredentialDefinition.forOrg(orgId)
          .then(function(result) {
            return _.find(result, function(creddef) {
              return  _.some(creddef.credential_definition_tags, { tag: tag })
            })
          })
      })
    }

    function cache(cacheKey, fn) {
      if (Object.prototype.hasOwnProperty.call(_cache, cacheKey)) return _cache[cacheKey]
      _cache[cacheKey] = fn()
      return _cache[cacheKey]
    }

    return paginate(CredentialDefinition)
  })
